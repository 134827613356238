import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { NAVIGATION } from 'config';
import PrivateRoute from "./PrivateRoute";

import { connect } from 'react-redux';
import { authActions, pageInfoActions, orderActions } from 'app/redux/actions';

import { getParam } from "utils/AppHelper";

//Screen imports
import { 
    Dashboard,
    Contact,
    TermsOfService, PrivacyPolicy, PaymentPolicy
} from 'app/screens';

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
}

const actionCreators = {
    userUpdate: authActions.user_update,
    userUpdateToken: authActions.user_update_token,
    changeInapp: pageInfoActions.change_inapp,
    clearOrder: orderActions.clear
}

const scroll_blacklist = ['shop'];

const HashNavigation = props => {
    let page_url = useLocation();

    const [hash, setHash] = useState('');

    useEffect(() => {
        if (page_url.hash !== '') {
            setHash(page_url.hash);
        } else {
            setHash('');
        }

        return () => {
            setHash('');
        }
    }, [page_url.hash, setHash])

    useEffect(() => {
        if (hash !== '') {
            try {
                document.querySelector(hash).scrollIntoView();   
            } catch (error) {
                console.log(document.querySelector(hash), error);
            }
        } else {
            let is_scroll_blacklist = false;
            scroll_blacklist.forEach(item => {
                if (page_url.pathname.indexOf(item) !== -1) {
                    is_scroll_blacklist = true;
                }
            })

            if (is_scroll_blacklist === false) {
                window.scrollTo(0, 0);
            }
        }
    }, [hash, page_url])
}

const AppNavigator = connect(mapStateToProps, actionCreators)((props) => {
    const { auth } = props;

    return (
        <>
        <HashNavigation />
        <Routes>
            <Route exact path={NAVIGATION.DASHBOARD} element={<Dashboard />} />

            <Route path={NAVIGATION.TERMS_OF_SERVICE} element={<TermsOfService />} />
            <Route path={NAVIGATION.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            
            <Route index path="*" element={<NoMatch />} />
        </Routes>
        </>
    )
})

const NoMatch = props => {
    return <Navigate to={NAVIGATION.DASHBOARD} replace={true} />
}

export default AppNavigator;