import { NAVIGATION } from './routes';

const BASE_API = ''
const BASE_API_LOCAMOS = ''
const BASE_IMAGE = ''

const HOMEPAGE = ''

const GOOGLE_CLIENT_ID = ''
const APPLE_CLIENT_ID = ''
const APPLE_REDIRECT_URI = ''

const SHIPPING_FEES_3KM = 20000

const BASE_API_COINSTORE = 'https://api.coinstore.com/'
const COINSTORE_API_KEY = '548b9d43c9f286a9dcb16ded907522dc'
const COINSTORE_API_SECRET = '9d830af0cbb6b5657c608d934012ff7a'
const COINSTORE_API_IP = '103.109.43.9'

const BASE_API_CMC = 'https://sandbox-api.coinmarketcap.com' // DEV
// const BASE_API_CMC = 'pro-api.coinmarketcap.com' // PROD
const CMC_API_KEY = '9013ee23-6e2f-45fc-981c-18749835890d'

export {
    BASE_API,
    BASE_API_LOCAMOS,
    HOMEPAGE,
    NAVIGATION,
    BASE_IMAGE,
    GOOGLE_CLIENT_ID,
    APPLE_CLIENT_ID, APPLE_REDIRECT_URI,
    SHIPPING_FEES_3KM,
    BASE_API_COINSTORE, COINSTORE_API_KEY, COINSTORE_API_SECRET, COINSTORE_API_IP,
    BASE_API_CMC, CMC_API_KEY
}