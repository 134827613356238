import React from 'react';
import './ContentPages.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { ScreenWithNavBarAndFooter } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const TermsOfService = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;

    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_name} Terms &amp; Conditions</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p>Welcome to {pageInfo.company_name}. These Terms and Conditions apply between you, the User of this <strong>app</strong>, and {pageInfo.company_name}, the owner of this app. By continuing to access and use the app, you are deemed to agree to comply with these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please stop using the app immediately.</p>
                        <p>No part of this app constitutes a contractual offer capable of acceptance.</p>
                        <h2><strong>1. Definitions and Interpretation</strong></h2>
                        <p>In this Agreement, the following terms shall have the following meanings:</p>
                        <ul>
                        <li><strong>"Account"</strong>: means the personal information, payment details, and login credentials used by Users to access Paid Content or other services on the app.</li>
                        <li><strong>"Content"</strong>: means any text, images, videos, audio, software, or data available on the app.</li>
                        <li><strong>"Facilities"</strong>: means all online tools, services, and information {pageInfo.company_name} makes available through the app, either now or in the future.</li>
                        <li><strong>"Services"</strong>: means the services you can access through the app, including {pageInfo.company_name}&rsquo;s translation and meeting solutions.</li>
                        <li><strong>"User"</strong>: means any third party accessing the app who is not employed by {pageInfo.company_name} in the course of their employment.</li>
                        <li><strong>"App"</strong>: means the {pageInfo.company_name} mobile application currently in use on your device.</li>
                        <li><strong>"We/Us/Our"</strong>: refers to {pageInfo.company_name}, the business responsible for this app.</li>
                        </ul>
                        <h2><strong>2. Right to Use the App and Passwords</strong></h2>
                        <p>2.1 <strong>Right to Use the App</strong>: We grant you a non-exclusive, personal, and revocable right to access and use this app. You may not use the app for unlawful or unauthorized purposes.</p>
                        <p>2.2 <strong>Passwords</strong>: You are responsible for protecting the confidentiality of your password and for any actions taken through your account. We are not responsible for any loss or damage arising from unauthorized use of your account.</p>
                        <h2><strong>3. Age Restrictions</strong></h2>
                        <p>Persons under the age of 18 should use this app only under the supervision of an adult. Any payment information provided must have the adult&rsquo;s consent.</p>
                        <h2><strong>4. Intellectual Property</strong></h2>
                        <p>4.1 All Content in this app, unless uploaded by Users, is the property of {pageInfo.company_name} or relevant third parties. Such material is protected by international intellectual property laws.</p>
                        <p>4.2 You may not copy, distribute, or store any Content from this app without our express written permission or that of the relevant parties.</p>
                        <h2><strong>5. Changes to the App and Terms</strong></h2>
                        <p>{pageInfo.company_name} reserves the right to modify or discontinue any part of the app without prior notice. We may amend these Terms and Conditions at any time, and such amendments will take effect immediately upon posting within the app.</p>
                        <h2><strong>6. Links to Other Websites</strong></h2>
                        <p>The app may contain links to external websites. {pageInfo.company_name} is not responsible for the content of such websites and disclaims all liability for any damage or loss arising from their use.</p>
                        <h2><strong>7. Limitation of Liability</strong></h2>
                        <p>7.1 <strong>Service Disclaimer</strong>: {pageInfo.company_name} provides the app and its services on an &ldquo;as is&rdquo; basis and does not guarantee the accuracy, reliability, or performance of any information or services provided through the app. We are not responsible for any technical errors, service disruptions, or any factors beyond our control, including but not limited to system failures or network issues.</p>
                        <p>7.2 <strong>Limitation of Liability</strong>: {pageInfo.company_name} shall not be liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the app.</p>
                        <h2><strong>8. Lawful Use and User Conduct</strong></h2>
                        <p>8.1 You may only use the app for lawful purposes. You are prohibited from using the app for any illegal activities, including but not limited to security breaches, sending spam, or violating intellectual property rights.</p>
                        <p>8.2 You may not upload, post, or distribute any content protected by copyright, trademark, or other proprietary rights without written permission from the rights holder.</p>
                        <h2><strong>9. Monitoring and Termination of Accounts</strong></h2>
                        <p>9.1 {pageInfo.company_name} reserves the right to monitor activity on the app and remove any content that violates these Terms and Conditions.</p>
                        <p>9.2 We may terminate or suspend your account if you breach these Terms. You may voluntarily terminate your account at any time by ceasing use of the app.</p>
                        <h2><strong>10. Privacy Policy</strong></h2>
                        <p>Use of this app is also governed by our Privacy Policy, which can be found within the app or at <Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link>.</p>
                        <h2><strong>11. Data Protection</strong></h2>
                        <p>{pageInfo.company_name} collects and uses your personal data in accordance with applicable data protection laws. We use your personal information to provide services, and notify you about products and services. You have the right to request access to, modify, or delete your personal data at any time by contacting us through the information provided in the app.</p>
                        <h2><strong>12. Cookies and Tracking Technologies</strong></h2>
                        <p>{pageInfo.company_name} may use cookies and other tracking technologies to improve your experience on the app. You can manage or delete cookies through your device&rsquo;s settings. By using this app, you consent to our use of cookies as described in our cookie policy.</p>
                        <h2><strong>13. User Responsibility for Equipment</strong></h2>
                        <p>Users are responsible for preparing, maintaining, and upgrading all hardware, software, and other elements necessary to use the app and related services. {pageInfo.company_name} is not responsible for any issues arising from users&rsquo; equipment or software not meeting system requirements.</p>
                        <h2><strong>14. Dispute Resolution</strong></h2>
                        <p>In the event of a dispute arising from the use of the app, both parties agree to attempt resolution through negotiation first. If negotiation fails, the dispute shall be referred to a court of competent jurisdiction in accordance with applicable law.</p>
                        <h2><strong>15. Future Changes</strong></h2>
                        <p>{pageInfo.company_name} reserves the right to modify, update, or discontinue any part of the app or its services at any time without notice. Any changes to the Terms will be posted within the app and will take effect immediately upon posting.</p>
                        <h2><strong>16. Termination and Suspension of Accounts</strong></h2>
                        <p>{pageInfo.company_name} reserves the right to terminate or suspend your account if you violate any of these Terms and Conditions. You may terminate your account at any time by ceasing use of the app and notifying us via email.</p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PrivacyPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_name} Privacy Policy</h2>
                    <p className="sec-description ms-0">
                        Your privacy is important. Last update: September 18th, 2024<br />
                        Effective as of September 18th, 2024
                    </p>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p>At {pageInfo.company_name}, we respect and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our app. By using {pageInfo.company_name}, you consent to the collection and use of your information as described in this policy.</p>
                        <h2><strong>1. Information We Collect</strong></h2>
                        <p>We may collect and process the following types of personal information:</p>
                        <ul>
                            <li><strong>Personal Information</strong>: Name, email address, and other identifying information you provide when creating an account or using the app.</li>
                            <li><strong>Usage Data</strong>: Information about your interaction with the app, including access times, pages viewed, and other usage data.</li>
                            <li><strong>Device Information</strong>: Information about the device you use to access the app, such as IP address, browser type, operating system, and mobile network information.</li>
                            <li><strong>Location Data</strong>: With your consent, we may collect and process information about your location when using certain features of the app.</li>
                        </ul>
                        <h2><strong>2. How We Use Your Information</strong></h2>
                        <p>We use the information we collect for the following purposes:</p>
                        <ul>
                            <li><strong>To Provide and Maintain the App</strong>: We use your personal information to operate and manage your account and to provide access to the app&rsquo;s services.</li>
                            <li><strong>To Improve User Experience</strong>: We analyze usage data to understand how users interact with the app and to enhance functionality.</li>
                            <li><strong>To Communicate with You</strong>: We may use your contact information to send you updates, notifications, and important information about your account or the app.</li>
                            <li><strong>To Comply with Legal Obligations</strong>: We may process your data to comply with applicable legal and regulatory requirements.</li>
                        </ul>
                        <h2><strong>3. Sharing Your Information</strong></h2>
                        <p>We do not share your personal information with third parties, except in the following situations:</p>
                        <ul>
                            <li><strong>With Service Providers</strong>: We may share your information with third-party service providers that assist us in operating the app, and improving our services.</li>
                            <li><strong>For Legal Reasons</strong>: We may disclose your information if required to do so by law or in response to legal requests, including court orders or government investigations.</li>
                            <li><strong>In Business Transfers</strong>: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                        </ul>
                        <h2><strong>4. Data Security</strong></h2>
                        <p>We implement reasonable security measures to protect your personal information from unauthorized access, loss, misuse, or disclosure. However, no system is 100% secure, and we cannot guarantee the security of your information.</p>
                        <h2><strong>5. Data Retention</strong></h2>
                        <p>We retain your personal information for as long as necessary to provide you with our services and for legitimate business purposes. If you close your account, we will delete or anonymize your personal data, unless we are required to retain it for legal reasons.</p>
                        <h2><strong>6. Your Rights</strong></h2>
                        <p>You have the following rights regarding your personal information:</p>
                        <ul>
                            <li><strong>Access</strong>: You have the right to request access to the personal information we hold about you.</li>
                            <li><strong>Correction</strong>: You have the right to request correction of any inaccurate or incomplete information.</li>
                            <li><strong>Deletion</strong>: You have the right to request the deletion of your personal information, subject to legal limitations.</li>
                            <li><strong>Opt-Out</strong>: You may opt out of receiving marketing communications by following the instructions in those messages or contacting us directly.</li>
                        </ul>
                        <h2><strong>7. Cookies and Tracking Technologies</strong></h2>
                        <p>We may use cookies and similar tracking technologies to enhance your experience on the app. Cookies are small text files stored on your device that help us recognize repeat users, remember preferences, and track usage patterns. You can manage or disable cookies through your device&rsquo;s settings, but doing so may affect your ability to use certain features of the app.</p>
                        <h2><strong>8. Children&rsquo;s Privacy</strong></h2>
                        <p>{pageInfo.company_name} is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under the age of 18. If we learn that we have collected such information without verification of parental consent, we will delete that information.</p>
                        <h2><strong>9. Changes to This Privacy Policy</strong></h2>
                        <p>We may update this Privacy Policy from time to time. Any changes will be posted within the app, and we will notify you of significant changes by email or through in-app notifications.</p>
                        <h2><strong>10. Contact Us</strong></h2>
                        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                        <p><strong>Email</strong>: {pageInfo.email}<br /><strong>Website: https://{pageInfo.domain}</strong></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PaymentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách thanh toán</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tiền mặt&nbsp;(COD)</li>
                            <li>Thanh toán chuyển khoản</li>
                        </ol>
                        <table cellSpacing={0} className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Hình thức</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Diễn giải</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>1. Tiền mặt (COD)</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Khách hàng sẽ thanh toán bằng tiền mặt trực tiếp cho nhân viên giao hàng, hoặc thanh toán cho nhân viên bán hàng mua tại cửa hàng</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>2. Chuyển khoản</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Sau khi hoàn tất đơn đặt hàng. Khách hàng vui lòng chuyển khoản về địa chỉ:</p>
                                        <p>&nbsp; - Số tài khoản: <a data-z-element-type="phone-number">785555888</a></p>
                                        <p><a data-z-element-type="phone-number">&nbsp; -&nbsp;</a>Tên chủ tài khoản: CONG TY CO PHAN LOCAMART VIET NAM</p>
                                        <p>&nbsp; - Tên ngân hàng: Ngân hàng TMCP Á Châu (ACB)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ShipmentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách vận chuyển</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p><span style={{ fontSize: '12pt' }}><span><span><strong><span>Thời gian giao hàng</span></strong><strong><span>: </span></strong><span>2-5 ngày làm việc đối với các thành phố,&nbsp;huyện/ xã trên khắp đất nước</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>- Đối với hình thức chuyển khoản, quý khách vui lòng chuyển trước giá trị đơn đặt hàng cho&nbsp;<strong>shop.locamart.vn, </strong>Các tài khoản có sử dụng SMS Banking nên sẽ nhận được thông tin ngay lập tức.</span></span><br />
                            <span><strong><span>shop.locamart.vn </span></strong><span>sẽ ngay lập tức xác nhận thông tin và chuyển hàng cho khách!</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trách nhiệm của bên giao hàng (cung cấp dịch vụ Logistic): chịu trách nhiệm về thời gian giao hàng, tình trạng hàng hóa đảm bảo khi đến tay khách hàng. Trường hợp xảy ra sự cố do lỗi của bên giao hàng hoàn toàn chịu trách nhiệm và chịu bồi thường theo giá trị sản phẩm. <strong>shop.locamart.vn, </strong>sẽ không chịu trách nhiệm do lỗi phát sinh chủ quan của bên giao hàng, trường hợp bất khả kháng </span></span><span><span>chúng tôi</span></span><span><span> sẽ hỗ trợ 50% tổn thất. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trường hợp phát sinh chậm trễ trong việc giao hàng, <strong>shop.locamart.vn </strong>sẽ liên hệ, thông tin kịp thời cho khách hàng và tạo cơ hội để khách hàng có thể hủy hợp đồng nếu muốn.</span></span></span></span></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const InspectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách kiểm hàng</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khi khách hàng nhận hàng và kiểm tra kĩ sản phẩm trước khi nhận (quý khách nên quay video trong quá trình mở hộp nhận hàng) và phản hồi ngay cho nhân viên giao hàng, bên chúng tôi sẽ tiến hành khắc phục đổi cho khách hàng.</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Nếu phát sinh những vấn đề ngoài ý muốn về phía Khách hàng, </span><span>shop.locamart.vn </span><span>sẽ hỗ trợ linh động giải quyết trong các trường hợp:</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Địa chỉ không chính xác</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khách muốn hẹn lịch giao hàng</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Những trường hợp bất khả kháng như thiên tai, tai nạn giao thông… shop.locamart.vn sẽ luôn hỗ trợ và đảm bảo quyền lợi của Quý Khách. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Mọi thắc mắc và góp ý vui lòng liên hệ</span><span>: </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✆</span></span><span style={{ color: '#212338' }}> Hotline: 0903287666</span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✉</span></span><span style={{ color: '#212338' }}> Email: </span><a href={`mailto:${pageInfo.email}`} style={{ color: '#0563c1', textDecoration: 'underline' }}>{pageInfo.email}</a> </span></span></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ReturnPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách đổi trả</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tất cả các sản phẩm của LocaMart được áp dụng đổi trả trong 2h tính từ thời điểm mua hàng.</li>
                            <li>Khi Khách hàng đến cửa hàng đổi trả hoặc yêu cầu nhân sự LocaMart nhận lại hàng cần có đầy đủ:
                                <ul>
                                    <li>Hóa đơn mua hàng</li>
                                    <li>Sản phẩm lỗi hỏng (nếu Khách Hàng gửi hình ảnh thì chỉ chấp nhận hình ảnh sản phẩm lỗi đối với các lỗi có thể quan sát bằng mắt thường như: Hàng mốc, thối, dập…)</li>
                                </ul>
                            </li>
                            <li>Chính sách đổi trả sẽ không áp dụng trong các trường hợp sau:
                                <ul>
                                    <li>Quý khách tự làm ảnh hưởng đến tình trạng bên ngoài như rách bao bì, bong tróc, bể vỡ, rơi rụng…</li>
                                    <li>Quý khách không thực hiện đúng theo cách thức bảo quản dẫn tới việc hư hỏng sản phẩm.</li>
                                </ul>
                            </li>
                            <li>Cách thức thu hồi hàng, hoàn trả tiền khi đổi trả hàng lỗi:
                                <ul>
                                    <li>Trường hợp Khách Hàng đến cửa hàng để đổi trả và hàng hóa đáp ứng điều kiện đổi trả, cửa hàng sẽ tiến hành đổi trả hàng thay thế ngay cho Khách Hàng.</li>
                                    <li>Đối với Khách Hàng khiếu nại qua hình thức khác (như qua tổng đài, fanpage…), chậm nhất 2h kể từ khi tiếp nhận yêu cầu đổi trả hàng của Khách Hàng, LocaMart sẽ liên hệ Khách hàng và tiến hành kiểm tra tình trạng hàng đổi trả.</li>
                                    <li>Trường hợp hàng hóa lỗi đáp ứng điều kiện đổi trả, LocaMart sẽ thu hồi hàng lỗi và đổi hàng hóa đạt yêu cầu thay thế cho Khách Hàng tương ứng với giá trị lượng hàng lỗi mà locaMart nhận được từ Khách Hàng. Nếu không còn hàng hóa để thay thế thì LocaMart sẽ hoàn tiền cho Khách Hàng bằng tiền mặt hoặc chuyển khoản.</li>
                                    <li>Tùy từng trường hợp, LocaMart có thể cử nhân sự hoặc thông qua một đối tác vận chuyển của mình để đến thu hồi hàng. Việc này sẽ được hướng dẫn, thỏa thuận cụ thể khi làm việc với Khách hàng và Khách hàng không phải trả thêm phí cho việc thu hồi hàng lỗi này.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const RightsProtectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách bảo đảm quyền lợi</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Khách hàng nhận rau hàng tuần 2kg/lần tại các điểm nhận rau</li>
                            <li>Rau cung cấp cho khách hàng đc tuyển chọn từ Mộc Châu và Đà Lạt, đạt chuẩn VietGAP</li>
                            <li>Gói bảo hiểm trị giá 10 triệu đồng trong trường hợp sức khỏe khách hàng bị ảnh hưởng do sử dụng rau do LocaMart cung cấp</li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const Template = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} - Chính sách bảo mật</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    TermsOfService,
    PrivacyPolicy,
    PaymentPolicy,
    ShipmentPolicy,
    InspectionPolicy,
    ReturnPolicy,
    RightsProtectionPolicy
};