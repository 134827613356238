import React, { useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-cube';
import './Slider.scss';

const Slider = props => {
	const { list, renderItem, className, currentIndex, indexChangeCallback, overrideConfig } = props;
	
    if (!list || list.length === 0) {
        return null;
    }

    const sliderItems = list.map((item, index) => <SwiperSlide key={index}>{renderItem(item, index)}</SwiperSlide>);

	return (
		<Swiper
			spaceBetween={24}
			className={className}
			onSlideChange={(swiper) => {
				// console.log(swiper.activeIndex);
				if (indexChangeCallback) {
					indexChangeCallback(swiper.activeIndex);
				}
			}}
			{...overrideConfig}
		>
			{
				!currentIndex || currentIndex === null
				? <></>
				: <SlideController currentIndex={currentIndex} />
			}
			{ sliderItems }
		</Swiper>
	)
}

const SlideController = props => {
	const { currentIndex } = props;
    
    const swiper = useSwiper();

    useEffect(() => {
        try {
			if (currentIndex !== null) {
				swiper.slideTo(currentIndex + 1, 0, false);
			}			
		} catch (error) {
		}
    }, [currentIndex, swiper])
    
    return (<></>)
}

export default Slider;