import React, { useEffect, useState } from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    const [navbarScroll, setNavbarScroll] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', handlePageScroll)

        return () => {
            document.removeEventListener('scroll', handlePageScroll)
        }
    }, [])

    function handlePageScroll(e) {
        // console.log('pageScroll', window.scrollY);
        if (window.scrollY > 70) {
            setNavbarScroll(true);
        } else {
            setNavbarScroll(false);
        }

    }

    return (
        <nav className={`navbar navbar-expand-lg navbar-dark fixed-top top-navbar ${navbarScroll ? 'scroll' : ''}`}>
            <div className="container-xl">
                <button className="navbar-toggler ms-3 order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={32}></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pt-3 pt-md-0 navbar-center">
                        <li className="nav-item">
                            <a href="#solution" className="nav-link">Solutions</a>
                        </li>
                        <li className="nav-item">
                            <a href="#features" className="nav-link">Features</a>
                        </li>
                        <li className="nav-item">
                            <a href="#benefits" className="nav-link">Benefits</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-3 order-2 order-lg-4 desktop">
                    <li className="nav-item">
                        <a href="#contact" className="btn btn-primary">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)((props) => {
	const { pageInfo } = props;
	const lang = pageInfo.display_lang;

	return (
		<footer className="site-footer text-white bg-secondary">
			<div className="container-xl">
                <div className="row justify-content-between">
                    <div className="col-md-5">
                        <img src="/public_assets/images/locamos-icon.svg" width={72} height={72}></img>
                        <h5>Join us to discover our latest solutions today!</h5>
                        <div className="subscribe-form mt-4 mb-5 mb-md-0">
							<form>
								<div className="input-group">
									<input className="form-control" type="email" placeholder="Subscribe to our Newsletter"></input>
									<button className="btn btn-primary btn-submit px-4" type="submit">
										Send
									</button>
								</div>
							</form>
						</div>
                        {/* <ul className="footer-links">
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-location-dot"></i>
                                    <span>Address: Bahnhofstrasse 21, 6300 Zug, Switzerland</span>
                                </div>
                            </li>
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-briefcase"></i>
                                    <span>Business Code: CHE-187.611.117</span>
                                </div>
                            </li>
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-user-tie"></i>
                                    <span>Representative: Mr. Peat Andrew Brian - Director</span>
                                </div>
                            </li>
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-envelope"></i>
                                    <span>Email: contact@locamos.com</span>
                                </div>
                            </li>
                        </ul> */}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-3">
                        <h5>RESOURCES</h5>
						<ul className="footer-links">
                            <li>
                                <Link to={NAVIGATION.DASHBOARD} className="nav-link">Solutions</Link>
                            </li>
                            <li>
                                <Link to={NAVIGATION.DASHBOARD} className="nav-link">Call Translate</Link>
                            </li>
                            <li>
                                <Link to={NAVIGATION.DASHBOARD} className="nav-link">Benefits</Link>
                            </li>
						</ul>
                    </div>
                    <div className="col-md-3">
                        <h5>SOCIALS</h5>
						<ul className="footer-links">
                            <li>
                                <a href="https://www.facebook.com" className="nav-link">Facebook</a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com" className="nav-link">LinkedIn</a>
                            </li>
                            <li>
                                <a href="https://x.com" className="nav-link">X (Twitter)</a>
                            </li>
						</ul>
                    </div>
                </div>
			</div>
            <div id="copyright" className="site-copyright">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-8 text-center text-md-start mb-3 mb-md-0 fw-semibold">
                            &copy; 2023 LocaMos.
                            All rights reserved.
                        </div>
                        <div className="col-md-4 text-center">
                            <Link to={NAVIGATION.PRIVACY_POLICY} className="me-4">{text.footer.links.privacy_policy[lang]}</Link>
                            <Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
		</footer>
	);
});

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}