import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider, CountdownTimer } from 'app/components';
import { Autoplay } from "swiper/modules";

import { decodeHtmlContent } from "utils/AppHelper";

import { motion } from "framer-motion";

import { homepageService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}


const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
	const { locale_string } = pageInfo;
    

    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg desktop" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/header-bg.jpg)' }}
                ></motion.div>
                <div className="section-bg mobile" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/header-bg-mobile.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header">
                        <div className="row">
                            <div className="col-md-8">
                                <motion.h2
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >
                                    <span className="text-primary">LocaMos</span><br /><span className="fw-normal">All-in-One AI Assistant for Meetings</span>
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    Smart Solution for Businesses to Go Global
                                </motion.p>
                                <motion.div
                                    initial={{ x: 200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="header-buttons mt-5"
                                >
                                    <a href="#" target="_blank" className="btn btn-primary btn-rounded">Request a Demo</a>
                                    <a href="#" target="_blank" className="btn btn-dark btn-rounded">Explore Features</a>
                                </motion.div>
                            </div>
                            <div className="col-12">
                                <div className="header-features">
                                    <motion.div
                                    initial={{ width: 0 }}
                                    whileInView={{ width: "100%" }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="header-features-bg"></motion.div>
                                    <div className="row">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="col-6 col-md-3 mb-3 mb-md-0"
                                        >
                                            <div className="header-feature-item">
                                                <div className="header-feature-line"></div>
                                                <p>AI Assistant</p>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="col-6 col-md-3 mb-3 mb-md-0"
                                        >
                                            <div className="header-feature-item">
                                                <div className="header-feature-line"></div>
                                                <p>Interpret 105+ Language</p>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="col-6 col-md-3"
                                        >
                                            <div className="header-feature-item">
                                                <div className="header-feature-line"></div>
                                                <p>Meeting Minutes</p>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="col-6 col-md-3"
                                        >
                                            <div className="header-feature-item">
                                                <div className="header-feature-line"></div>
                                                <p>Seamless Intergration</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="solution" className="page-section solution-section">
                <div className="section-bg video-bg">
                    <video muted autoPlay loop>
                        <source src="/public_assets/images/home/solution-section/bg.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <div className="section-bg tiles-bg"></div>
                <div className="section-tag">
                    <div className="container-xl">
                        <motion.div
                            initial={{ y: -50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 1 }}
                            viewport={{ once: true }}
                            className="section-tag-text">
                            Our Innovative Solution
                        </motion.div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >
                                    LocaMos's solution is an AI revolution, taking advantage of AI technology and advanced platforms to provide precise, in-depth translation, replacing interpreters and translation booths.<br /><br />
                                    With Plug &amp; Play system, it easily integrates with existing infrastructure such as speakers, amplifiers, or computers for conferences or webinars, online meetings.
                                </motion.h2>
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 1 }}
                                    viewport={{ once: true }}
                                    className="mt-5"
                                >
                                    <a href="#" target="_blank" className="btn btn-primary btn-rounded">Learn More</a>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="page-section features-section">
                <div className="section-bg desktop" style={{ backgroundImage: 'url(/public_assets/images/home/features-section/conference.jpg)' }}></div>
                <div className="section-bg mobile" style={{ backgroundImage: 'url(/public_assets/images/home/features-section/conference.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row justify-content-end">
                        <div className="col-md-7">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Offline Event Translation</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    International Business Gateway in your hand
                                </motion.p>
                            </div>
                            <div className="sec-content">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">01</p>
                                            <div className="feature-item-content">
                                                <h3>Multi-Lingual<br />Translation Support</h3>
                                                <p>Provide instant translation for 105+ languages, with AI-powered translation</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .25 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">02</p>
                                            <div className="feature-item-content">
                                                <h3>Public &amp; Individual<br />Delivering</h3>
                                                <p>LocaMos delivers translation result through conference's speaker, display subtitle or personal mobile in realtime</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">03</p>
                                            <div className="feature-item-content">
                                                <h3>Speak in your<br />Audience's Language</h3>
                                                <p>Speakers can speak directly in the native language of their partners</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features_online" className="page-section features_online-section">
                <div className="section-bg desktop" style={{ backgroundImage: 'url(/public_assets/images/home/features-section/online-meeting.jpg)' }}></div>
                <div className="section-bg mobile" style={{ backgroundImage: 'url(/public_assets/images/home/features-section/online-meeting.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Online Meeting Translation</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    Cross-border Business Connecting
                                </motion.p>
                            </div>
                            <div className="sec-content">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">01</p>
                                            <div className="feature-item-content">
                                                <h3>Multi-Platform<br />Translation Support</h3>
                                                <p>Supports translation in online meetings on platforms such as Zoom, Teams, Meet, or Skype</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .25 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">02</p>
                                            <div className="feature-item-content">
                                                <h3>Contextual<br />Translation</h3>
                                                <p>Automatically process and correct translation results based on the current context of the speech</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">03</p>
                                            <div className="feature-item-content">
                                                <h3>Tailored Translation Service</h3>
                                                <p>Provide translation for specialized fields like Engineering, Technology, Marketing, Finance, Healthcare, Legal,...</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="feature-item"
                                        >
                                            <p className="feature-item-number">04</p>
                                            <div className="feature-item-content">
                                                <h3>Seamless Communication</h3>
                                                <p>Enabling seamless conversation in your partner's language without interruptions</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="why" className="page-section why-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/why-section/why-bg.jpg)' }}></div>
                <div className="section-tag">
                    <div className="container-xl">
                        <motion.div
                            initial={{ y: -50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 1 }}
                            viewport={{ once: true }}
                            className="section-tag-text">
                            Why Choose LocaMos
                        </motion.div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >
                                    Unlocking Global <br className="desktop" />Communication
                                </motion.h2>
                            </div>
                            <div className="sec-content">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="why-item"
                                        >
                                            <img src="/public_assets/images/home/why-section/server.png" width={55} height={55}></img>
                                            <div className="why-item-content">
                                                <h3>Enterprise-Grade Translation Hardware</h3>
                                                <p>Our advanced translation hardware ensures both high-performance and easy to integrate with any infrastructure</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="why-item"
                                        >
                                            <img src="/public_assets/images/home/why-section/translation.png" width={55} height={55}></img>
                                            <div className="why-item-content">
                                                <h3>Reliable, High-Quality Translations</h3>
                                                <p>Experience reliable and high-quality translations with our state-of-the-art hardware and AI-powered solutions</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="why-item"
                                        >
                                            <img src="/public_assets/images/home/why-section/tailored.png" width={55} height={55}></img>
                                            <div className="why-item-content">
                                                <h3>Tailored Translation Service</h3>
                                                <p>We offer tailored translation services to meet the specific needs of businesses and organizations, ensuring accurate and culturally sensitive translations</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="why-item"
                                        >
                                            <img src="/public_assets/images/home/why-section/support.png" width={55} height={55}></img>
                                            <div className="why-item-content">
                                                <h3>Expert Advisory Support</h3>
                                                <p>Our team of expert advisors provides comprehensive support and guidance to optimize your translation processes</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="benefits" className="page-section benefits-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >
                            Business Benefits of using LocaMos
                        </motion.h2>
                    </div>
                    <div className="row justify-content-around">
                        <div className="col-md-4 mb-5 mb-md-0">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M20 20v160c44.183 0 80-35.817 80-80S64.183 20 20 20z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                            <path d="M100 100c0 44.183 35.817 80 80 80V20c-44.183 0-80 35.817-80 80z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>Save 90% of Cost</h3>
                                    <p>Replaces traditional services with a lower-cost AI solution</p>
                                </div>
                            </motion.div>
                        </div>
                        <div className="col-md-4 mb-5 mb-md-0">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M100 20c-44.118 0-80 35.882-80 80s35.882 80 80 80 80-35.882 80-80h-80V20z" fill="#2377FC"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>Easy Global Expansion</h3>
                                    <p>Translates in 105 languages for easy market access</p>
                                </div>
                            </motion.div>
                        </div>
                        <div className="col-md-4">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M102.887 20v117.469c32.371 0 58.613-26.296 58.613-58.734C161.5 46.296 135.258 20 102.887 20z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                            <path d="M38.5 121.266C38.5 153.704 64.742 180 97.113 180V62.532c-32.371 0-58.613 26.296-58.613 58.734z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>Enhance Professionalism</h3>
                                    <p>Seamless 2-way communication makes a strong impression</p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className="row justify-content-around mt-5">
                        <div className="col-md-4 mb-5 mb-md-0">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M20 20v160c44.183 0 80-35.817 80-80S64.183 20 20 20z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                            <path d="M100 100c0 44.183 35.817 80 80 80V20c-44.183 0-80 35.817-80 80z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>Increase Efficiency</h3>
                                    <p>Real-time translation shortens communication time and decision-making</p>
                                </div>
                            </motion.div>
                        </div>
                        <div className="col-md-4 mb-5 mb-md-0">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M100 20c-44.118 0-80 35.882-80 80s35.882 80 80 80 80-35.882 80-80h-80V20z" fill="#2377FC"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>High Security</h3>
                                    <p>Ensures meeting content remains secure, without storing data</p>
                                </div>
                            </motion.div>
                        </div>
                        <div className="col-md-4">
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                viewport={{ once: true }}
                                className="benefit-item"
                            >
                                <div className="benefit-item-icon">
                                    <svg data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                                        <g>
                                            <path d="M102.887 20v117.469c32.371 0 58.613-26.296 58.613-58.734C161.5 46.296 135.258 20 102.887 20z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                            <path d="M38.5 121.266C38.5 153.704 64.742 180 97.113 180V62.532c-32.371 0-58.613 26.296-58.613 58.734z" fill="#2377FC" clipRule="evenodd" fillRule="evenodd" data-color="1"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="benefit-item-content">
                                    <h3>Easy Integration</h3>
                                    <p>Plug &amp; Play system integrates quickly with business infrastructure</p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="targets" className="page-section targets-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/targets-section/targets-bg.jpg)' }}></div>
                <div className="section-tag">
                    <div className="container-xl">
                        <motion.div
                            initial={{ y: -50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 1 }}
                            viewport={{ once: true }}
                            className="section-tag-text">
                            Targeted Audience
                        </motion.div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="row justify-content-end">
                        <div className="col-md-7">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >
                                    Designed Especially for You!
                                </motion.h2>
                            </div>
                            <div className="sec-content">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="target-item"
                                        >
                                            <img src="/public_assets/images/home/targets-section/sme.png" width={55} height={55}></img>
                                            <div className="target-item-content">
                                                <h3>Small and Medium <br className="desktop" />Enterprises - SMEs</h3>
                                                <p>Expand globally with optimized translation costs</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="target-item"
                                        >
                                            <img src="/public_assets/images/home/targets-section/unit.png" width={55} height={55}></img>
                                            <div className="target-item-content">
                                                <h3>Event Organizers</h3>
                                                <p>Real-time translation enhances international conference experiences</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="target-item"
                                        >
                                            <img src="/public_assets/images/home/targets-section/school.png" width={55} height={55}></img>
                                            <div className="target-item-content">
                                                <h3>Universities and <br className="desktop" />Educational Institutions</h3>
                                                <p>Supports accurate translation for international programs</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-6">
                                        <motion.div
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .25, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="target-item"
                                        >
                                            <img src="/public_assets/images/home/targets-section/telco.png" width={55} height={55}></img>
                                            <div className="target-item-content">
                                                <h3>Hotels and <br className="desktop" />Conference Venues</h3>
                                                <p>Provide multilingual translation services to enhance event satisfaction</p>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className="page-section contact-section">
                <div className="container-xl">
                    <div className="row justify-content-between">
                        <div className="col-md-4">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >
                                    <span>Let LocaMos help you expand business to the global stage. Request a demo and discover our seamless communication solution for your business</span>
                                    <img src="/public_assets/images/home/contact-section/quotes.png" width={144} height={144}></img>
                                </motion.h2>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Your Name</label>
                                        <input type="text" className="form-control" id="name"></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="company" className="form-label">Your Company</label>
                                        <input type="text" className="form-control" id="company"></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" id="email"></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                        <input type="tel" className="form-control" id="phone_number"></input>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea rows={3} className="form-control" id="message"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mt-3">
                                        <button className="btn btn-primary rounded-0 w-100">Request a Demo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const NewsSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const news = [
        {
            "title": "[Yahoo] LocaMos Global AG Officially Inaugurates New Headquarters in Zug, Switzerland, Bridging MarTech and Blockchain Innovation",
            "description": "LocaMos Global AG, an emerging name in the technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://finance.yahoo.com/news/locamos-global-ag-officially-inaugurates-034100325.html",
            "img_url": "https://s.yimg.com/ny/api/res/1.2/wCXsAxlezV.JLsLmkuKnmQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY4MQ--/https://media.zenfs.com/en/newsfile_64/adb3aa3e19ddfa3700b678348d18581f",
        },
        {
            "title": "[Business Insider] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://markets.businessinsider.com/news/stocks/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain-1032786035",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
        {
            "title": "[Benzinga] LocaMos Global AG commits to applying technology to address real-life challenges. The company offers a range of tools that help attract and engage customers through high-experiential features to support physical businesses, creating smoother business operations and growth.",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.benzinga.com/pressreleases/23/11/35636290/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain",
            "img_url": "/public_assets/images/news/news-2.jpg",
        },
        {
            "title": "[News Max] LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland",
            "description": "LocaMos Global AG, a technology company based in Zug, Switzerland, recently established, is making waves by combining two crucial facets in the technology industry: MarTech (Marketing Technology) and Blockchain. MarTech emphasizes utilizing technology to optimize and enhance marketing campaigns, while Blockchain has the potential to transform many sectors through its security and transparency in transactions.",
            "url": "https://pr.newsmax.com/article/LocaMos-Global-AG-Pioneering-a-Revolutionary-Blend-of-MarTech-and-Blockchain?storyId=6549e967aabbe7000889a161&fbclid=IwAR2GzfuyxswWFZHM5aLdlhJKoWBRjTR4aCwjGiU1DqcqJf5NNIxUdbXUSOk_aem_AehULbt_gCLI7P0HxUPS64lhOmWMIdh46HdHz3cLs3a-NmaEkM0gYbla8wừycQujJh8",
            "img_url": "https://storage.googleapis.com/mmstudio-images/gallery/AhvOgFGA4jcj9Xtx9NZIpIwF4Vw2/89112505-1699329968-1.jpg",
        },
        {
            "title": "[TGAM] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.theglobeandmail.com/investing/markets/markets-news/MarketersMEDIA%20Newswire/21772309/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain/?fbclid=IwAR2gZL6arEordyYiLCe4KFjHIgOq2Tl6uJJjBauzNabt9qAy2barC64eymg_aem_Aehjv52c61JvXo8gw0HebSzgI5HKXPePgq0BPU-ueX52dFqE_r7yiH5vjJbDtxAdx7g",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
    ]

    return (
        <section id="news" className={`page-section news-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <h2 className="sec-title">Our Latest News</h2>
                    <a href="#" className="btn btn-lg btn-primary btn-200 px-5">View all posts</a>
                </div>
                <div className="sec-content">
                    <Slider
                        className="news-slider"
                        list={news}
                        renderItem={(item, index) => (
                            <a href={item.url} target="_blank" className="article">
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        )}
                        overrideConfig={{
                            slidesPerView: 1,
                            breakpoints: {
                                768: {
                                    slidesPerView: 3
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
})

export {
    Dashboard,
    NewsSection
}